const eligibility = {

    fetchTrigger: 0,
    currentFocus: 0,
    map: null,
    marker: null,
    addressReady: 0,

    init() {
        if (document.querySelectorAll(".main.eligibilite").length > 0) {
            this.initAddressSearch();
            this.initAddressSelect();
            this.initEligibilityMap();
            this.initSendRequest();

            // clear selector if focusout
            document.querySelector(".main.eligibilite .address_search").addEventListener('focusout', function(e) {
                document.querySelector(".main.eligibilite .address-selector").innerHTML = "";
            });

            // search when focus in
            document.querySelector(".main.eligibilite .address_search").addEventListener('focusin', function(e) {
                eligibility.searchAddressAPI(document.querySelector(".main.eligibilite .address_search").value)
            });
        }
    },

    initSendRequest() {

        document.querySelector(".main.eligibilite button.submit").addEventListener('click', function(e) {

            let element = document.querySelector(".main.eligibilite .address_search")

            if (document.querySelectorAll(".main.eligibilite .address-selector a").length > 0) { //selector opened
                document.querySelector(".main.eligibilite .address_error").innerHTML = ""
                return false;
            }
            else if (eligibility.addressReady && element.value !== 'undefined' && element.dataset.long !== 'undefined' && element.dataset.lat !== 'undefined') {
                document.querySelector(".main.eligibilite .address_error").innerHTML = ""

                let form = document.querySelector(".main.eligibilite form");

                form.method = "POST";
                form.action = "/eligibilite";
                form.onsubmit = "";

                //send coordoniates
                var coordinates = document.createElement("INPUT");
                coordinates.name="coordinates"
                coordinates.value = element.dataset.long + ";" + element.dataset.lat;
                coordinates.type = 'hidden'
                form.appendChild(coordinates); 

                //send FTTH IMB ID 
                var imb_id = document.createElement("INPUT");
                imb_id.name="imb_id"
                imb_id.value = ""; //futur use
                imb_id.type = 'hidden'
                form.appendChild(imb_id); 

                form.submit();
            }
            else {
                document.querySelector(".main.eligibilite .address_error").innerHTML = "Veuillez rechercher et sélectionner une adresse"

            }
        })

    },

    initEligibilityMap() {
        eligibility.map = L.map('map').setView([44.841050, -0.580426], 12);

        var cartolight = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        }).addTo(eligibility.map);

        var satelit = L.tileLayer('https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}', {
            maxZoom: 19,
            apikey: 'choisirgeoportail',
            format: 'image/jpeg',
            style: 'normal',
            attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>'
        });

        var baseMaps = {
            "Carte": cartolight,
            "Satelite": satelit
        };
        
        L.control.layers(baseMaps).addTo(eligibility.map);

    },


    initAddressSelect() {
        document.querySelector(".main.eligibilite .address-selector").addEventListener('mousedown', function(e) {
            e.preventDefault();
            e.stopPropagation();
            eligibility.defineAddress(e.target);
        });


    },

    defineAddress(element) {

        document.querySelector(".main.eligibilite .address_search").value = element.name;

        for( var d in element.dataset) {
            console.log(d, element.dataset[d])
            document.querySelector(".main.eligibilite .address_search").dataset[d] = element.dataset[d]
        }

        //remove marker if exist
        if (eligibility.marker != null) {
            eligibility.map.removeLayer(eligibility.marker);
        }

        var acribIcon = L.icon({
            iconUrl: 'acrib-marker-icon.png',
            shadowUrl: 'marker-shadow.png',
        
            iconSize:     [25, 35], // size of the icon
            shadowSize:   [40, 40], // size of the shadow
            iconAnchor:   [12, 40], // point of the icon which will correspond to marker's location
            shadowAnchor: [12, 40],  // the same for the shadow
            popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
        });


        //add marker
        eligibility.marker = L.marker([element.dataset.lat, element.dataset.long], {icon: acribIcon}).addTo(eligibility.map);

        // add popup
        eligibility.marker.bindPopup(element.name).openPopup()

        // pan on marker
        eligibility.map.setView([element.dataset.lat, element.dataset.long], 16);

        //clear address box
        document.querySelector(".main.eligibilite .address-selector").innerHTML = "";

        eligibility.addressReady = 1;

    },

    setActive() {
        var nbVal = document.querySelectorAll(".main.eligibilite .address-selector a").length;

        /*a function to classify an item as "active":*/
        if (!nbVal)
            return false;
        /*start by removing the "active" class on all items:*/
        document.querySelectorAll(".main.eligibilite .address-selector a").forEach((element) => {
            element.classList.remove("active");
        });

        // Bidouille mathématique pour contraindre le focus dans la plage du nombre de résultats
        this.currentFocus = ((this.currentFocus + nbVal - 1) % nbVal) + 1;

        document.querySelector(".main.eligibilite .address-selector a:nth-child(" + this.currentFocus + ")").classList.add("active");
    },

    initAddressSearch() {

        document.querySelector(".main.eligibilite .address_search").addEventListener('keyup', function(e) {

            // Stop la propagation par défaut
            e.preventDefault();
            e.stopPropagation();

            eligibility.addressReady = 0;

            if (e.keyCode === 38) { // Flèche HAUT
                eligibility.currentFocus--;
                eligibility.setActive();
                return false;
              } else if (e.keyCode === 40) { // Flèche BAS
                eligibility.currentFocus++;
                eligibility.setActive();
                return false;
            } else if (e.keyCode === 27) { // escape
                document.querySelector(".main.eligibilite .address-selector").innerHTML = "";
                return false;
              } else if (e.keyCode === 13) { // Touche ENTREE
                if (eligibility.currentFocus > 0) {
                  // On défini l'adresse
                  eligibility.defineAddress(document.querySelector(".main.eligibilite .address-selector a.active"));
                }
                return false;
              }

              // reinitialisation de la position du focus list
              eligibility.currentFocus = 0

              eligibility.searchAddressAPI(document.querySelector(".main.eligibilite .address_search").value);
        });
    },

    searchAddressAPI(search_q) {

        // Si le champ adresse est vide, on nettoie la liste des suggestions et on ne lance pas de requête.
        eligibility.currentFocus = 0;
        if (search_q.length < 4) {
            document.querySelector(".main.eligibilite .address-selector").innerHTML = "";
            return false;
        }

        clearTimeout(self.fetchTrigger);
        
        // On lance une minuterie pour une requête vers l'API.
        self.fetchTrigger = setTimeout(function() {
            // On lance la requête sur l'API

            fetch('https://api-adresse.data.gouv.fr/search/' + '?' + new URLSearchParams({
                q: search_q,
                limit: 5,
                autocomplete: 1
            }))
            .then((response) => response.json())
            .then(function (json) {
                let liste = "";
                
                json.features.forEach(function (address){
                    eligibility.geojsonFeature = address;
                    let cooladdress = address.properties.name + " " + address.properties.postcode + " " + address.properties.city;
                    liste += '<a class="list-group-item" href="#" name="' + address.properties.label + '" data-housenumber="' + address.properties.housenumber + '" data-street="' + address.properties.street + '"data-name="' + address.properties.name + '" data-lat="' + address.geometry.coordinates[1] + '" data-long="' + address.geometry.coordinates[0] + '" data-postcode="' + address.properties.postcode + '" data-city="' + address.properties.city + '">' + cooladdress + '</a>';
                })
                document.querySelector('.address-selector').innerHTML = liste;
            })

        }, 200);
    },
}

export default eligibility