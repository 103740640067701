const navbar = {
  init() {
    document.querySelector(".navbar .burger").addEventListener('click', () => {
      navbar.open()
    })

    document.querySelector(".navbar .cross").addEventListener('click', () => {
      navbar.close()
    })
  },

  open() {
    document.querySelector(".navbar .menu").classList.add('active')
    document.querySelector(".navbar .cross").classList.add('active')
    document.querySelector(".navbar .burger").classList.remove('active')
  },

  close() {
    document.querySelector(".navbar .menu").classList.remove('active')
    document.querySelector(".navbar .burger").classList.add('active')
    document.querySelector(".navbar .cross").classList.remove('active')
  }
}

export default navbar;