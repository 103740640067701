// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import "../shared/styles/styles.scss";

import navbar from "../shared/javascript/components/navbar";
import accordeon from "../shared/javascript/components/accordeon";
import pricetable from "../shared/javascript/components/pricetable";
import eligibility from "../shared/javascript/components/eligibility";

document.addEventListener('turbolinks:load', () => {
  navbar.init()
  accordeon.init()
  pricetable.init()
  eligibility.init()
})

