const accordeon = {
  
  selectedItem: 0,
  toggleClass: 'open',
  
  init() {
    document.querySelectorAll("[data-accordeon]").forEach(e => {
      this.initAccordeon(e)
    })
  },

  initAccordeon(accordeonHTML) {
    accordeonHTML.querySelectorAll("[data-accordeon-item] > .head").forEach((item, index) => {
      item.addEventListener('click', (_e) => {
        accordeon.selectedItem = index;
        accordeon.refresh(accordeonHTML);
      })
    });

    this.refresh(accordeonHTML)
  },

  refresh(accordeonHTML) {
    let selectedItemHTML = accordeonHTML.querySelectorAll("[data-accordeon-item]")[accordeon.selectedItem];
    if(!selectedItemHTML.classList.contains(accordeon.toggleClass)) {
      selectedItemHTML.classList.add(accordeon.toggleClass)
    }

    [...accordeonHTML.querySelectorAll("[data-accordeon-item]")].filter((_item, index) => index != accordeon.selectedItem).forEach(item => item.classList.remove(accordeon.toggleClass))
  }
}

export default accordeon