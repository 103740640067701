const pricetable = {
  activeColIndex: 1,
  hoveredColIndex: 1,

  activeClass: 'active',
  hoverClass: 'hovered',

  init() {
    document.querySelectorAll('.pricetable table').forEach(e => {
      pricetable.initTable(e)
      this.refreshSelected(e)
      this.refreshHover(e)
    })
  },

  initTable(tableHTML) {
    tableHTML.querySelectorAll('th').forEach((th, i) => {
      if(i != 0) {
        th.addEventListener('click', (e) => {
          pricetable.activeColIndex = i;
          pricetable.refreshSelected(tableHTML)
        })
  
        tableHTML.querySelectorAll("tr").forEach((tr) => {
          tr.querySelectorAll('td, th').forEach((td, i) => {
            if(i != 0) {
              td.addEventListener('mouseover', (e) => {
                pricetable.hoveredColIndex = i;
                pricetable.refreshHover(tableHTML)
              })
            }
          })
        })
      }
    })
  },

  refreshSelected(tableHTML) {
    tableHTML.querySelectorAll("tr").forEach((tr) => {
      tr.querySelectorAll('td, th').forEach((td, i) => {
        if(i == pricetable.activeColIndex) {
          if(!td.classList.contains(pricetable.activeClass)) {
            td.classList.add(pricetable.activeClass)
          }
        } else {
          td.classList.remove(pricetable.activeClass)
        }
      })
    })
  },

  refreshHover(tableHTML) {
    tableHTML.querySelectorAll("tr").forEach((tr) => {
      tr.querySelectorAll('td, th').forEach((td, i) => {
        if(i == pricetable.hoveredColIndex) {
          if(!td.classList.contains(pricetable.hoverClass)) {
            td.classList.add(pricetable.hoverClass)
          }
        } else {
          td.classList.remove(pricetable.hoverClass)
        }
      })
    })
  },
}

export default pricetable